import Main from "../components/noteApp/main";
import "./noteApp.css";

const NoteApp = () => {
  return (
    <div className="noteApp">
      <Main />
    </div>
  );
};

export default NoteApp;
